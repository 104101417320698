<template>
  <div>
    <v-row justify="center" style="margin-top: 20px;">
      <v-col cols="8">
        <v-banner color="warning" lines="one" text="创建一个新的表情包..." stacked>
          <template v-slot:actions>
            <v-btn @click="createStickerFunc">点击创建</v-btn>
            <v-btn @click="cloneStickerFunc">快速克隆</v-btn>
          </template>
        </v-banner>
      </v-col>
    </v-row>
    <v-row justify="start" style="margin-top: 20px; padding: 10px">
      <v-col v-for="item in list" :key="item.name" sm="6" xl="3" xxl="4" md="4" lg="4">
        <v-card :title="item.title" :subtitle="item.name">
          <v-card-actions>
            <v-btn @click="viewSticker(item.id)">查看</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { getSettings } from '@/api/user'
import { getStickerList } from '@/api/sticker'

export default defineComponent({
  name: 'HomeView',
  mounted() {
    getSettings().then(res => {
      if (!res.data.botToken) {
        this.$router.push({ path: '/settings' });
      } else {
        this.getStickerListFunc();
      }
    });
  },
  data: () => ({
    list: []
  }),
  methods: {
    getStickerListFunc() {
      getStickerList().then(res => {
        // if (res.data.length == 0) {
        //   this.$router.push({ path: '/create' });
        // }
        this.list = res.data;
      });
    },
    createStickerFunc(event) {
      this.$router.push({ path: '/create' });
    },
    cloneStickerFunc(event) {
      this.$router.push({ path: '/clone' });
    },
    viewSticker(id) {
      this.$router.push({
        path: '/view',
        query: {
          id
        }
      })
    }
  }
});
</script>
