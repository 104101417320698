<template>
  <div>
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
  </div>
</template>

<script>
import { getUser } from '@/api/user'

export default {
  name: 'UserInfo',
  mounted() {
    // this.load();
  },
  watch: {
    $route: {
      immediate: true,
      handler(val, oldVal) {
        this.load();
      },
    }
  },
  data: () => ({
    breadcrumbs: []
  }),
  methods: {
    load() {
      getUser().then(res => {
        this.breadcrumbs = [];
        this.breadcrumbs.push("欢迎使用TG表情包管理");
        if (res.data) {
          this.breadcrumbs.push(res.data.name);
        }
      });
    }
  }
}
</script>
