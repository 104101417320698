<template>
   <v-app id="inspire">
    <v-app-bar flat>

      <template v-slot:append>
        <v-btn icon="mdi-information" @click="urlPush('/about')"></v-btn>

        <v-btn icon="mdi-cog" @click="urlPush('/settings')"></v-btn>
      </template>

      <v-container class="fill-height d-flex align-center">
        <v-avatar
          class="me-10 ms-4"
          color="grey-darken-1"
          size="32"
          image="https://sticker.kylelin1998.com/favicon.ico"
        ></v-avatar>

        <v-btn @click="urlPush('/home')" variant="text">首页</v-btn>
        <!-- <v-btn @click="urlPush('/market')" variant="text">市场</v-btn> -->

        <v-spacer></v-spacer>

        <v-responsive max-width="260">
          <v-text-field
            density="compact"
            hide-details
            variant="solo"
          ></v-text-field>
        </v-responsive>
      </v-container>
    </v-app-bar>

    <v-main class="bg-grey-lighten-3">
      <v-container>
        <v-row>
          <!-- <v-col cols="2">
            <v-sheet rounded="lg">
              <v-list rounded="lg">
                <v-list-item
                  v-for="n in 5"
                  :key="n"
                  link
                >
                  <v-list-item-title>
                    List Item {{ n }}
                  </v-list-item-title>
                </v-list-item>

                <v-divider class="my-2"></v-divider>

                <v-list-item
                  link
                  color="grey-lighten-4"
                >
                  <v-list-item-title>
                    Refresh
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-sheet>
          </v-col> -->

          <v-col>
            <v-sheet
              min-height="70vh"
              rounded="lg"
            >
              <UserInfo></UserInfo>
              <router-view :key="$route.fullPath"></router-view>
              
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
// Components
import UserInfo from '@/components/UserInfo.vue';

export default {
  name: 'App',
  components: {
    UserInfo
  },
  data: () => ({
  }),
  methods: {
    urlPush(push) {
      this.$router.push({ path: push })
    }
  }
}
</script>
