import service from './axios'

export function getStickerList() {
  return service({
    url: '/sticker/list',
    method: 'post'
  })
}

export function createSticker(form) {
  const formdata = new FormData();
  formdata.append('name', form.name);
  formdata.append('title', form.title);
  formdata.append('type', form.type);
  for (let i = 0;i < form.files.length;i++) {
    formdata.append('files', form.files[i])
  }

  return service.post('/sticker/create', formdata);
}

export function updateSticker(form) {
  const formdata = new FormData();
  formdata.append('id', form.id);
  formdata.append('title', form.title);
  formdata.append('type', form.type);
  for (let i = 0;i < form.files.length;i++) {
    formdata.append('files', form.files[i])
  }

  return service.post('/sticker/update', formdata);
}

export function getSticker(id) {
  return service({
    url: '/sticker/get',
    method: 'post',
    data: {
      id
    }
  })
}

export function deleteSticker(id) {
  return service({
    url: '/sticker/delete',
    method: 'post',
    data: {
      id
    }
  })
}

export function deleteFile(data) {
  return service({
    url: '/sticker/deleteFile',
    method: 'post',
    data
  })
}

export function cloneSticker(data) {
  return service({
    url: '/sticker/clone',
    method: 'post',
    data
  })
}

export function getCloneStatus() {
  return service({
    url: '/sticker/getCloneStatus',
    method: 'post'
  })
}

export function getCloneList() {
  return service({
    url: '/sticker/getCloneList',
    method: 'post'
  })
}