import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'

const routes = [
  {
    path: '/',
    name: 'Index',
    component: HomeView
  },
  {
    path: '/home',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('@/views/AboutView.vue')
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('@/views/SettingsView.vue')
  },
  {
    path: '/create',
    name: 'Create',
    component: () => import('@/views/SaveSticker.vue')
  },
  {
    path: '/update',
    name: 'Update',
    component: () => import('@/views/UpdateSticker.vue')
  },
  {
    path: '/view',
    name: 'ViewSticker',
    component: () => import('@/views/ViewSticker.vue')
  },
  {
    path: '/clone',
    name: 'CloneSticker',
    component: () => import('@/views/CloneSticker.vue')
  },
  {
    path: '/market',
    name: 'Market',
    component: () => import('@/views/MarketView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  const token = localStorage.token
  if(token || to.name === 'Login' || to.name === 'About' || to.name === 'Market' || to.name === 'ViewSticker') {
      next()
  } else {
      next({
        name: 'Login',
        query: {
          redirect: to.path
        }
      })
  }
})

export default router
