import service from './axios'

export function getLoginUrl() {
  return service({
    url: '/user/getLoginUrl',
    method: 'post'
  })
}

export function getUser() {
  return service({
    url: '/user/get',
    method: 'post'
  })
}

export function logout() {
  return service({
    url: '/user/logout',
    method: 'post'
  })
}

export function check(st) {
  return service({
    url: '/user/check',
    method: 'post',
    data: {
      s: st
    }
  })
}

export function getSettings() {
  return service({
    url: '/user/getSettings',
    method: 'post'
  })
}

export function bindBotToken(botToken) {
  return service({
    url: '/user/bindBotToken',
    method: 'post',
    data: {
      botToken: botToken
    }
  })
}