<template>
  <div class="login">
    <v-row class="login" alignContent="center" align="center" justify="center" style="height: ;">
    <v-col cols="8">
        <v-card
        loading
        title="需要登录"
        subtitle="请先登录才能使用表情包管理"

        >
        <v-card-actions>
            <v-btn @click="login">点击登录</v-btn>
        </v-card-actions>
        </v-card>
    </v-col>
  </v-row>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { getLoginUrl, check } from '@/api/user'

export default defineComponent({
  name: 'Login',
  data: () => ({
    checkInterval: 0
  }),
  beforeUnmount () {
    clearInterval(this.checkInterval);
  },
  methods: {
    login(event) {
      getLoginUrl().then(res => {
        this.checkIntervalFunc(res.data);
        window.open(res.data, '_blank');
      });
    },
    checkIntervalFunc(st) {
      clearInterval(this.checkInterval);
      this.checkInterval = setInterval(() => {
        check(st).then(res => {
          if (res.data.check) {
            localStorage.setItem("token", res.data.token);
            this.$router.push({ path: '/home' });
          }
        });
      }, 1500);
    }
  }
});
</script>

<style scoped>
    .login {
        min-height: 70vh;
    }
</style>
